import React from 'react';
import styles from './log-in-section.module.css';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import AppleIcon from '@material-ui/icons/Apple';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import loginScreenDa from '../../assets/login-screen-da.png';
import loginScreenNo from '../../assets/login-screen-no.png';
import loginScreenEn from '../../assets/login-screen-en.png';
import loginScreenPhoneDa from '../../assets/login-screen-phone-da.png';
import loginScreenPhoneNo from '../../assets/login-screen-phone-no.png';
import loginScreenPhoneEn from '../../assets/login-screen-phone-en.png';
import Images from '../../models/images';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import config from '../../config';

const images: Images = {
  desktop: {
    no: loginScreenNo,
    da: loginScreenDa,
    en: loginScreenEn,
  },
  phone: {
    no: loginScreenPhoneNo,
    da: loginScreenPhoneDa,
    en: loginScreenPhoneEn,
  },
};
interface Props {
  background: string;
  forwardRef: React.RefObject<HTMLDivElement>;
}

const LogInSection: React.FC<Props> = props => {
  const { t, i18n } = useTranslation();

  const isAndroid = () : boolean => {
    return navigator.userAgent.toLowerCase().indexOf('android') > -1;
  };

  const isIOS = () : boolean => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  };


  return (
    <>
      <div ref={props.forwardRef} className={styles.section} style={{ background: props.background }}>
        <div className={styles.img}>
          <img src={images.desktop[i18n.language]} alt="Screen" />
        </div>
        <div className={styles.rightSide}>
          <h1>{t('Get started in no time')}</h1>
          <ul>
            <li>
              <div className={styles.numbers}>1</div>
              <div className={styles.descriptionButtons}>
                <p>{t('Sign up with')}</p>
                <div className={styles.icons}>
                  <Button className={styles.facebookIcon}><h3>f</h3></Button>
                  <Button><AppleIcon className={styles.appleIcon}/></Button>
                  <Button><MailOutlineIcon className={styles.mailIcon}/></Button>
                </div>
              </div>
            </li>
            <li>
              <div className={styles.numbers}>2</div>
              <div className={styles.descriptionButtons}>
                <p><Trans i18nKey="Connect your stores, and you're <br/> on your way to saving money"/> </p>
                <Button onClick={() => {
                  if (isAndroid()) {
                    ReactGA.event({
                      category: 'Button',
                      action: 'Click',
                      label: 'Play Store',
                    });
                    ReactPixel.trackCustom('PlayStoreButtonClick');
                    window.location.href = config.androidAppUrl;
                  } else if (isIOS()) {
                    ReactGA.event({
                      category: 'Button',
                      action: 'Click',
                      label: 'App Store',
                    });
                    ReactPixel.trackCustom('AppStoreButtonClick');
                    window.location.href = config.iosAppUrl;
                  }
                }}>{t('Connect')}</Button>
              </div>
            </li>
          </ul>
        </div>
      </div> 
      <div ref={props.forwardRef} className={styles.sectionPhone} style={{ background: props.background }}>
        <div className={styles.rightSide}>
          <h1><Trans i18nKey="Getting started and gaining overview is that easy"/></h1>
          <div className={styles.img}>
            <img src={images.phone[i18n.language]} alt="Phone screen" />
          </div>
          <div className={styles.content}>
            <p>{t('Sign up with')}</p>
            <div className={styles.icons}>
              <Button className={styles.facebookIcon}><h3>f</h3></Button>
              <Button><AppleIcon className={styles.appleIcon}/></Button>
              <Button><MailOutlineIcon className={styles.mailIcon}/></Button>
            </div>
            <p>
              {t('and connect your stores')}
            </p>
            <Button onClick={() => {
              if (isAndroid()) {
                ReactGA.event({
                  category: 'Button',
                  action: 'Click',
                  label: 'Play Store',
                });
                ReactPixel.trackCustom('PlayStoreButtonClick');
                window.location.href = config.androidAppUrl;
              } else if (isIOS()) {
                ReactGA.event({
                  category: 'Button',
                  action: 'Click',
                  label: 'App Store',
                });
                ReactPixel.trackCustom('AppStoreButtonClick');
                window.location.href = config.iosAppUrl;
              }
            }}>{t('Connect')}</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogInSection;
