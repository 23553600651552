import React from 'react';
import styles from './features.container.module.css';
import {ReactComponent as GulvLeft} from '../../assets/gulv_left.svg';
import {ReactComponent as GulvRight} from '../../assets/gulv_right.svg';
import { Trans } from 'react-i18next';


interface Props {
  background: string;
  forwardRef: React.RefObject<HTMLDivElement>;
}

const FeaturesContainer: React.FC<Props> = props => {
  return (
    <section ref={props.forwardRef} className={styles.section} style={{ background: props.background }}>
      <div className={styles.features}>
        <div className={styles.first}>
          <GulvLeft className={styles.gulvLeft}/>
          <p><Trans i18nKey="Food budget app"/></p>
          <GulvRight className={styles.gulvRight}/>
        </div>
        <div className={styles.second}>
          <GulvLeft className={styles.gulvLeft}/>
          <p><Trans i18nKey="Savings apps we love"/></p>
          <GulvRight className={styles.gulvRight}/>
        </div>
        <div className={styles.third}>
          <GulvLeft className={styles.gulvLeft}/>
          <p><Trans i18nKey="Groceries in one place"/></p>
          <GulvRight className={styles.gulvRight}/>
        </div>
      </div>
    </section>
  );
};

export default FeaturesContainer;
