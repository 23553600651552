import React, { PropsWithChildren } from 'react';
import styles from './top-pop-up.module.css';

interface Props {
  isPopUpModuleOpen: boolean;
  onClose: () => void;
}

const TopPopUp: React.FC<PropsWithChildren<Props>> = props => {
  return (
    <div
      className={`${styles.popup} ${props.isPopUpModuleOpen ? styles.open : ''}`}
      onClick={() => props.isPopUpModuleOpen && props.onClose()}
    >
      <div
        className={`${styles.container} ${props.isPopUpModuleOpen ? styles.open : ''}`}
        onClick={ev => ev.stopPropagation()}
      >
        {props.children}
      </div>
    </div>
  );
};

export default TopPopUp;
