import * as React from 'react';
import styles from './language-change.module.css';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';
import danishLogo from '../../assets/danish.png';
import englishLogo from '../../assets/english.png';
import norwegianLogo from '../../assets/norwegian.png';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';


interface Props {
  transparent: boolean;
}

type CountryType = {
  [key: string]: {
      src: string;
      alt: string;
  }
};


const LanguageChange: React.FC<Props> = ({ transparent }) => {
  const history = useHistory();
  const location = useLocation();
  const {t, i18n} = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language.toUpperCase());
  
  const handleChange = (event: any) => {
    const pathWithoutLanguage = location.pathname.replace(/^\/[a-zA-Z]{2}/, '');
    history.replace(`/${event.target.value.toLocaleLowerCase()}${pathWithoutLanguage}`);
    setLanguage(event.target.value);
    i18n.changeLanguage(event.target.value.toLowerCase());
    localStorage.setItem('selectedLanguage', event.target.value.toLowerCase());
  };

  const country : CountryType= {
    DA : {
      src: danishLogo,
      alt: 'DK Logo',
    },
    NO : {
      src: norwegianLogo,
      alt: 'NO Logo',
    },
    EN : {
      src: englishLogo,
      alt: 'EN Logo',
    },
  };
  const useStyles = makeStyles((theme) => ({
    transparentBackground: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
      padding: 0,
    },
    menu: {
      marginTop: '0.5rem',
    },
  }));

  const classes = useStyles();
  
  return (
    <div className={styles.wrap}>
      <Select
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              borderRadius: '1.25rem', 
            },
          },
          disableScrollLock: true,
          className: classes.menu,
        }}
        inputProps={{
          classes: {
            root: classes.transparentBackground,
          },
        }}
        SelectDisplayProps={{
          style: {
            paddingRight: '0rem',
            paddingLeft: '3rem',
          },
        }}
        
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        className={styles.select}
        value={language}
        onChange={handleChange}
        renderValue={() => (language)}
        IconComponent={()=> <img className={styles.selectIcon} src={country[language].src} alt={country[language].alt}/>}
        style={{color: transparent ? 'white' : 'black'}}
      >
        <MenuItem key="DA" value="DA">
          <img src={danishLogo} alt={'danish'} className={styles.logoImg} />
          {t('Danish')} {language==='DA' && <CheckIcon  style={{ fill: '#0072ea', marginLeft: '0.25rem' }}/>}
        </MenuItem>
        <MenuItem key="EN" value="EN">
          <img src={englishLogo} alt={'english'} className={styles.logoImg}/>
          {t('English')} {language==='EN' && <CheckIcon  style={{ fill: '#0072ea', marginLeft: '0.25rem' }}/>}
        </MenuItem>
        <MenuItem key="NO" value="NO">
          <img src={norwegianLogo} alt={'norwegian'} className={styles.logoImg} />
          {t('Norwegian')} {language==='NO' && <CheckIcon  style={{ fill: '#0072ea', marginLeft: '0.25rem' }}/>}
        </MenuItem>
      </Select>
      
    </div>
  );
};

export default LanguageChange;