import React from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import {Link as RouterLink} from 'react-router-dom';
import styles from './footer.module.css';
import config from '../../config';
import Link from '@material-ui/core/Link';
import appStoreBadge from '../../assets/app-store-badge.png';
import appStoreBadgeEn from '../../assets/app-store-badge-en.svg';
import playStoreBadgeEn from '../../assets/play-store-badge-en.svg';
import playStoreBadge from '../../assets/play-store-badge.png';
import facebookLogo from '../../assets/facebook-logo-whiteBg.png';
import instagramLogo from '../../assets/instagram-logo-whiteBg.png';
import youtubeLogo from '../../assets/youtube-logo-whiteBg.png';
import { Link as MuiLink, List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Images from '../../models/images';

interface Props {
  openCookiesPopUp: () => void;
}

const badges : Images = {
  playStore: {
    da: playStoreBadge,
    no: playStoreBadge,
    en: playStoreBadgeEn,
  },
  appStore: {
    da: appStoreBadge,
    no: appStoreBadge,
    en: appStoreBadgeEn,
  },
};
const Footer: React.FC<Props> = props => {
  const footerRef = React.createRef<HTMLDivElement>();
  const { t, i18n } = useTranslation();

  return (
    <>
      <section ref={footerRef} className={styles.section}>
        <div className={styles.linksLogosContainer}>
          <div className={styles.linksOutsideContainer}>
            <div className={styles.linksContainer}>
              <h3>Optius</h3>
              <div className={styles.links}>
                <Link to={`/${i18n.language}/about`} component={RouterLink} className={styles.link}>{t('About Optius')}</Link>
                <Link to={`/${i18n.language}/faq`} component={RouterLink} className={styles.link}>{t('FAQ')}</Link>
                <Link to={`/${i18n.language}/contact`} component={RouterLink} className={styles.link}>{t('Contact')}</Link>
              </div>
            </div>
            <div className={styles.linksContainer}>
              <h3>{t('Terms and Conditions')}</h3>
              <div className={styles.links}>
                <Link to={`/${i18n.language}/terms-of-use`} component={RouterLink} className={styles.link}>{t('Terms of Use')}</Link>
                <Link to={`/${i18n.language}/privacy-policy`} component={RouterLink} className={styles.link}>{t('Privacy Policy')}</Link>
                <span onClick={props.openCookiesPopUp} className={styles.link}>Cookies</span>
              </div>
            </div>
          </div>
          <div className={styles.download}>
            <MuiLink
              href={config.androidAppUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Click',
                  label: 'Play Store',
                });
                ReactPixel.trackCustom('PlayStoreButtonClick');
              }}
            >
              <img src={badges.playStore[i18n.language]} alt="Get in Play Store" />
            </MuiLink>
            <MuiLink
              href={config.iosAppUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Click',
                  label: 'App Store',
                });
                ReactPixel.trackCustom('AppStoreButtonClick');
              }}
            >
              <img src={badges.appStore[i18n.language]} alt="Get in App Store" />
            </MuiLink>
          </div>
        </div>
        <div className={styles.breakLine}></div>
        <div className={styles.socialMediaOptionsContainer}>
          <p>© 2023, {t('Optius reserves all rights.')}</p>
          <List className={styles.socialMediaOptions}>
            <MuiLink href={config.facebookUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
              <img src={facebookLogo} alt="Facebook" />
            </MuiLink>
            <MuiLink href={config.instagramUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
              <img src={instagramLogo} alt="Instagram" />
            </MuiLink>
            <MuiLink href={config.youtubeUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
              <img src={youtubeLogo} alt="Youtube" />
            </MuiLink>
          </List>
        </div>
      </section>
      <section ref={footerRef} className={styles.sectionPhone}>
        <div className={styles.linksLogosContainer}>
          <div className={styles.linksOutsideContainer}>
            <div className={styles.linksContainer}>
              <h3>Optius</h3>
              <div className={styles.links}>
                <Link to={`/${i18n.language}/about`} component={RouterLink} className={styles.link}>{t('About Optius')}</Link>
                <Link to={`/${i18n.language}/faq`} component={RouterLink} className={styles.link}>{t('FAQ')}</Link>
                <Link to={`/${i18n.language}/contact`} component={RouterLink} className={styles.link}>{t('Contact')}</Link>
              </div>
            </div>
            <div className={styles.linksContainer}>
              <h3>{t('Terms and Conditions')}</h3>
              <div className={styles.links}>
                <Link to={`/${i18n.language}/terms-of-use`} component={RouterLink} className={styles.link}>{t('Terms of Use')}</Link>
                <Link to={`/${i18n.language}/privacy-policy`} component={RouterLink} className={styles.link}>{t('Privacy Policy')}</Link>
                <Link to={`/${i18n.language}/privacy-policy`} component={RouterLink} className={styles.link}>{t('Cookies')}</Link>
              </div>
            </div>
          </div>
          <div className={styles.download}>
            <MuiLink
              href={config.androidAppUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Click',
                  label: 'Play Store',
                });
                ReactPixel.trackCustom('PlayStoreButtonClick');
              }}
            >
              <img src={badges.playStore[i18n.language]} alt="Get in Play Store" />
            </MuiLink>
            <MuiLink
              href={config.iosAppUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Click',
                  label: 'App Store',
                });
                ReactPixel.trackCustom('AppStoreButtonClick');
              }}
            >
              <img src={badges.appStore[i18n.language]} alt="Get in App Store" />
            </MuiLink>
          </div>
        </div>
        <div className={styles.breakLine}></div>
        <div className={styles.socialMediaOptionsContainer}>
          <List className={styles.socialMediaOptions}>
            <MuiLink href={config.facebookUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
              <img src={facebookLogo} alt="Facebook" />
            </MuiLink>
            <MuiLink href={config.instagramUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
              <img src={instagramLogo} alt="Instagram" />
            </MuiLink>
            <MuiLink href={config.youtubeUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
              <img src={youtubeLogo} alt="Youtube" />
            </MuiLink>
          </List>
          <p>© 2023, {t('Optius reserves all rights.')}</p>
        </div>
      </section>
    </>
  );
};

export default Footer;