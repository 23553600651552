import React from 'react';
import styles from './privacy-policy.module.css';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { gql, useQuery } from '@apollo/client';
import { ResultSingle } from '../../models/graphql';
import sanitize from 'sanitize-html';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy: React.FC = () => {
  const { t, i18n } = useTranslation();

  const getPrivacyPolicy = gql`
  query {
    optiusPrivacyPolicy(locale: "${i18n.language === 'en' ? 'en' : i18n.language === 'no' ? 'nb' : 'da'}") {
      data {
        attributes {
          Content
        }
      }
    }
  }
`;

  React.useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: '/privacy-policy'});
    ReactPixel.pageView();
    document.title = 'Optius - Privatlivspolitik';
  }, []);
  const { data } = useQuery<Record<string, ResultSingle<{Content: string}>>>(getPrivacyPolicy);


  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <h1>{t('Privacy Policy')}</h1>
        {data && <div dangerouslySetInnerHTML={{__html: sanitize(data.optiusPrivacyPolicy.data.attributes.Content)}} />}
      </div>
    </div>
  );
};

export default PrivacyPolicy;