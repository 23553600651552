import React from 'react';
import styles from './spending-section.module.css';
import tick from '../../assets/tick.png';
import { Trans, useTranslation } from 'react-i18next';
import spendingScreenNo from '../../assets/spending-screen-no.png';
import spendingScreenDa from '../../assets/spending-screen-da.png';
import spendingScreenEn from '../../assets/spending-screen-en.png';
import Images from '../../models/images';

const images: Images = {
  desktop: {
    no: spendingScreenNo,
    da: spendingScreenDa,
    en: spendingScreenEn,
  },
};

interface Props {
  forwardRef: React.RefObject<HTMLDivElement>;
  background: string;
}
 
const SpendingSection: React.FC<Props> = props => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div ref={props.forwardRef} className={styles.section} style={{backgroundImage: props.background}}>
        <div className={styles.text}>
          <h1><Trans i18nKey="Find out what you're spending your money on"/></h1>
          <ul>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p><Trans i18nKey="See which categories and products you <br /> buy the most and can save the most on"/></p>
            </li>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p><Trans i18nKey="See nutritional content and ingredients <br /> on your products"/></p>
            </li>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p>{t('Get easy insight into your habits')}</p>
            </li>
          </ul>
        </div>
        <div className={styles.img}>
          <img src={images.desktop[i18n.language]} alt="Screen" />
        </div>
      </div>
      <div ref={props.forwardRef} className={styles.sectionPhone}>
        <div className={styles.text}>
          <h1><Trans i18nKey="See what you're spending your money on"/></h1>
          <div className={styles.img}>
            <img src={images.desktop[i18n.language]} alt="Phone screen" />
          </div>
          <ul>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p><Trans i18nKey="See which categories and products you <br /> buy the most and can save the most on"/></p>
            </li>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p><Trans i18nKey="See nutritional content and ingredients"/></p>
            </li>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p>{t('Get easy insight into your habits')}</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SpendingSection;
