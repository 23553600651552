import React from 'react';
import styles from './cookies-popup.module.css';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const COOKIES_PREF_KEY = '_cookies_enabled';

interface Props {
  open: boolean;
  close: () => void;
}

const CookiesPopup: React.FC<Props> = props => {
  const { t } = useTranslation();
  const declineCookies = () => {
    localStorage.setItem(COOKIES_PREF_KEY, 'false');
    props.close();
  };

  const acceptCookies = () => {
    localStorage.setItem(COOKIES_PREF_KEY, 'true');
    props.close();
  };

  return (
    <div className={`${styles.wrap} ${props.open ? styles.open : ''}`}>
      <div className={styles.text}>
        <h3>{t('We have cookies')}</h3>
        <p>{t('At Optius.app, we use cookies to improve the user experience. By pressing \'Accept all,\' you accept the use of cookies.')}</p>
        <p>{t('You can change your consent at any time.')} <Link to="/privacy-policy">{t('Read more.')}</Link></p>
      </div>
      <div className={styles.buttons}>
        <Button className={styles.button} variant="outlined" color="primary" onClick={declineCookies}>{t('REJECT ALL')}</Button>
        <Button className={styles.button} variant="contained" color="primary" onClick={acceptCookies}>{t('ACCEPT ALL')}</Button>
      </div>
    </div>
  );
};

export default CookiesPopup;