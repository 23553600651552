import React from 'react';
import styles from './terms-of-use.module.css';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { gql, useQuery } from '@apollo/client';
import { ResultSingle } from '../../models/graphql';
import sanitize from 'sanitize-html';
import { useTranslation } from 'react-i18next';

const TermsOfUse: React.FC = () => {
  const { t, i18n } = useTranslation();

  const getTermsOfUse = gql`
    query {
      optiusTermsOfUse(locale: "${i18n.language === 'en' ? 'en' : i18n.language === 'no' ? 'nb' : 'da'}") {
        data {
          attributes {
            Content
          }
        }
      }
    }
  `;

  React.useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: '/terms-of-use'});
    ReactPixel.pageView();
    document.title = 'Optius - Brugerbetingelser';
  }, []);
  const { data } = useQuery<Record<string, ResultSingle<{Content: string}>>>(getTermsOfUse);


  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <h1>{t('Terms of Use')}</h1>
        {data && <div dangerouslySetInnerHTML={{__html: sanitize(data.optiusTermsOfUse.data.attributes.Content)}} />}
      </div>
    </div>
  );
};

export default TermsOfUse;