import React from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import styles from './intro-section.module.css';
import config from '../../config';
import appStoreBadge from '../../assets/app-store-badge.png';
import appStoreBadgeEn from '../../assets/app-store-badge-en.svg';
import playStoreBadgeEn from '../../assets/play-store-badge-en.svg';
import playStoreBadge from '../../assets/play-store-badge.png';
import homePictureDa from '../../assets/home-screen-da.png';
import homePictureNo from '../../assets/home-screen-no.png';
import homePictureEn from '../../assets/home-screen-en.png';
import homePicturePhoneDa from '../../assets/home-screen-phone-da.png';
import homePicturePhoneNo from '../../assets/home-screen-phone-no.png';
import homePicturePhoneEn from '../../assets/home-screen-phone-en.png';
import { Trans, useTranslation } from 'react-i18next';
import Images from '../../models/images';

const images: Images = {
  desktop: {
    no: homePictureNo,
    da: homePictureDa,
    en: homePictureEn,
  },
  phone: {
    no: homePicturePhoneNo,
    da: homePicturePhoneDa,
    en: homePicturePhoneEn,
  },
  badgeApp: {
    no: appStoreBadge,
    da: appStoreBadge,
    en: appStoreBadgeEn,
  },
  badgePlay: {
    no: playStoreBadge,
    da: playStoreBadge,
    en: playStoreBadgeEn,
  },
};

const IntroSection: React.FC = () => {
  const { i18n } = useTranslation();
  return (
    <section className={styles.section} id="intro">
      <div className={styles.introSection}>
        <div>
          <h1><Trans i18nKey="Automatic  <br /> overview of <br /> your groceries"/></h1>
          <p className={styles.description}><Trans i18nKey="Save money on groceries, which <br /> are a significant part of your spending"/></p>
          <div className={styles.download}>
            <a
              href={config.iosAppUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Click',
                  label: 'App Store',
                });
                ReactPixel.trackCustom('AppStoreButtonClick');
              }}
            >
              <img src={images.badgeApp[i18n.language]} alt="App Store" />
            </a>
            <a
              href={config.androidAppUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'Click',
                  label: 'Play Store',
                });
                ReactPixel.trackCustom('PlayStoreButtonClick');
              }}
            >
              <img src={images.badgePlay[i18n.language]} alt="Play Store" />
            </a>
          </div>
        </div>
        <div className={styles.img}>
          <img src={images.desktop[i18n.language]} alt="Intro" />
        </div>
      </div>

      <div className={styles.introSectionPhone}>
        <h1><Trans i18nKey="Automatic  <br /> overview of your <br /> groceries"/></h1>
        <div className={styles.img}>
          <img src={images.phone[i18n.language]} alt="Intro" />
        </div>
        <p className={styles.description}><Trans i18nKey="Save money on groceries, which are a <br/ > significant part of your spending"/></p>
        <div className={styles.download}>
          <a
            href={config.iosAppUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Click',
                label: 'App Store',
              });
              ReactPixel.trackCustom('AppStoreButtonClick');
            }}
          >
            <img src={images.badgeApp[i18n.language]} alt="App Store" />
          </a>
          <a
            href={config.androidAppUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              ReactGA.event({
                category: 'Button',
                action: 'Click',
                label: 'Play Store',
              });
              ReactPixel.trackCustom('PlayStoreButtonClick');
            }}
          >
            <img src={images.badgePlay[i18n.language]} alt="Play Store" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
