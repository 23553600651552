import React from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import styles from './faq.module.css';
import sanitizeHtml from 'sanitize-html';
import { gql, useQuery } from '@apollo/client';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IntroSection from '../../components/intro-faq-section/intro-section';
import { useTranslation } from 'react-i18next';

const Faq: React.FC = () => {
  const { i18n, t } = useTranslation();

  const getFaqs = gql`
    query {
      optiusFaqs(
        pagination: {pageSize: 100}
        locale: "${i18n.language === 'en' ? 'en' : i18n.language === 'no' ? 'nb' : 'da'}"
        sort: "Order"
        filters: {
          or: [
            { Countries: { eq: "All" } }
            { Countries: { eq: "${i18n.language === 'no' ? 'Norway' : 'Denmark'}" } }
          ]
        }
      ) {
        data {
          id
          attributes {
            Question
            Answer
            Order
            IsDataRelated
          }
        }
      }
    }
  `;

  const { data } = useQuery(getFaqs);
  React.useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: '/faq'});
    ReactPixel.pageView();
    document.title = 'Optius - FAQ';
  }, []);

  return (
    <>
      <IntroSection />

      <div className={styles.wrap}>
        <h1>{t('Hello! How can we assist you?')}</h1>
        <div className={styles.content}>
          {
            data?.optiusFaqs.data.map((item: {id: string, attributes: {Question: string, Answer: string}}) => {
              return (
                <Accordion
                  key={item.id} 
                  className={styles.accordion}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
                    <h3>{item.attributes.Question}</h3>
                  </AccordionSummary>
                  <AccordionDetails className={styles.details}>
                    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(item.attributes.Answer)}} />
                  </AccordionDetails>
                </Accordion>
              );
            })
          }
        </div>
      </div>
    </>
  );
};

export default Faq;
