import React from 'react';
import styles from './support-container.module.css';
import innovationsfondenLogo from '../../assets/innovationsfonden-logo.png';
import industriensFondLogo from '../../assets/industriens-fond-logo.png';
import { useTranslation } from 'react-i18next';

interface Props {
  background: string;
  forwardRef: React.RefObject<HTMLDivElement>;
}

const SupportContainer: React.FC<Props> = props => {
  const { t } = useTranslation();
  return (
    <section ref={props.forwardRef} className={styles.section} style={{ background: props.background }}>
      <h3>{t('Supported by')}</h3>
      <div>
        <div>
          <img src={innovationsfondenLogo} alt="innovationsfondenLogo"></img>
        </div>
        <div>
          <img src={industriensFondLogo} alt="industriensFondLogo"></img>
        </div>
      </div>
    </section>
  );
};

export default SupportContainer;
