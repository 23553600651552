import React from 'react';
import styles from './recalls-section.module.css';
import { Trans, useTranslation } from 'react-i18next';
import recallsScreenNo from '../../assets/recalls-screen-no.png';
import recallsScreenEn from '../../assets/recalls-screen-en.png';
import recallsScreenDa from '../../assets/recalls-screen-da.png';
import Images from '../../models/images';

const images: Images = {
  desktop: {
    no: recallsScreenNo,
    da: recallsScreenDa,
    en: recallsScreenEn,
  },
};

interface Props {
  img?: string;
  forwardRef: React.RefObject<HTMLDivElement>;
}

const RecallsSection: React.FC<Props> = props => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div ref={props.forwardRef} className={styles.section}>
        <div className={styles.img}>
          <img src={images.desktop[i18n.language]} alt="Screen" />
        </div>
        <div className={styles.text}>
          <h1>
            <Trans i18nKey="Did you know that the Food Agency recalls approximately 15 products every single month?"/>
          </h1>
          <p>
            {t('Optius notifies you directly about recalled products you\'ve purchased! If you\'re not aware of all recalls, Optius keeps you informed about potentially harmful products.')}
          </p>
        </div>
      </div>

      <div ref={props.forwardRef} className={styles.sectionPhone}>
        <div className={styles.text}>
          <h1>          
            <Trans i18nKey="Did you know that the Food Agency recalls approximately 15 products every <br/> single month?" />
          </h1>
          <div className={styles.img}>
            <img src={images.desktop[i18n.language]} alt="Phone screen" />
          </div>
          <p>
            <Trans i18nKey="Optius will notify you directly if you have purchased recalled products that are potentially harmful to health"/>
          </p>
        </div>
      </div>
    </>
  );
};

export default RecallsSection;
