import React from 'react';
import styles from './intro-section.module.css';
import contact from '../../assets/contact.jpg';
import { Trans, useTranslation } from 'react-i18next';

const IntroSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className={styles.section} id="intro" style={{ backgroundImage: `url(${contact})` }}>
      <div className={styles.introSection}>
        <h1>{t('Contact us')}</h1>
        <p className={styles.description}>
          <Trans i18nKey="Here on the page, you have the opportunity to contact us <br /> and get answers to all your questions if <br /> you can't find them in our"/> <a href={`/${i18n.language}/faq`}>{t('FAQ')}</a>
        </p>
      </div>

      <div className={styles.introSectionPhone}>
        <div className={styles.textContainer}>
          <h1>{t('Contact us')}</h1>
          <p className={styles.description}>
            <Trans i18nKey="Here on the page, you have the opportunity to contact us <br /> and get answers to all your questions if <br /> you can't find them in our"/> <a href={`/${i18n.language}/faq`}>{t('FAQ')}</a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
