import React from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import './App.css';
import Header from './components/header/header';
import TopPopUp from './components/top-pop-up/top-pop-up';
import Drawer from './components/drawer/drawer';
import About from './views/about/about';
import Faq from './views/faq/faq';
import Contact from './views/contact/contact';
import Home from './views/home/home';
import Footer from './components/footer/footer';
import CookiesPopup, { COOKIES_PREF_KEY } from './components/cookies-popup/cookies-popup';
import TermsOfUse from './views/terms-of-use/terms-of-use';
import PrivacyPolicy from './views/privacy-policy/privacy-policy';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';

const App: React.FC = () => {
  const [isCookiesPopupOpen, setIsCookiesPopupOpen] = React.useState<boolean>(!localStorage.getItem(COOKIES_PREF_KEY));
  const [isCookiesEnabled, setIsCookiesEnabled] = React.useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const pixelOptions = {
    autoConfig: true,
    debug: false,
  };
  const { i18n } = useTranslation();

  const enableCookies = () => {
    ReactGA.initialize('G-RZQHMZNNFN');
    ReactGA.send({hitType: 'pageview', page: '/home'});
    ReactPixel.init('297176421833082', undefined, pixelOptions);
    ReactPixel.pageView();
  };

  React.useEffect(() => {
    setIsCookiesEnabled(localStorage.getItem(COOKIES_PREF_KEY) === 'true');
  }, [isCookiesPopupOpen]);

  React.useEffect(() => {
    if (isCookiesEnabled) {
      enableCookies();
    }
  }, [isCookiesEnabled]);
  
  return (
    <>
      {
        i18n.language===undefined || i18n.language==='dev' ?
          <div className="progress-wrap">
            <CircularProgress size={60} color="primary" /> 
          </div>
          :
          <>
            <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
            <TopPopUp isPopUpModuleOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
              <Drawer onClose={() => setIsMenuOpen(false)}/>
            </TopPopUp>
            <Switch>
              <Route path={`/:lang/privacy-policy`} component={PrivacyPolicy} />
              <Route path={`/privacy-policy`} >
                <Redirect to={`/${i18n.language}/privacy-policy`}/>
              </Route>
              <Route path={`/:lang/terms-of-use`} component={TermsOfUse} />
              <Route path={`/terms-of-use`} >
                <Redirect to={`/${i18n.language}/terms-of-use`}/>
              </Route>
              <Route path={`/:lang/about`} component={About} />
              <Route path={`/about`} >
                <Redirect to={`/${i18n.language}/about`}/>
              </Route>
              <Route path={`/:lang/faq`} component={Faq} />
              <Route path={`/faq`}>
                <Redirect to={`/${i18n.language}/faq`}/>
              </Route>
              <Route path={`/:lang/contact`} component={Contact} />
              <Route path={`/contact`} >
                <Redirect to={`/${i18n.language}/contact`}/>
              </Route>
              <Route path={`/:lang`} component={Home} />
              <Route exact path={`/`}>          
                <Redirect to={`/${i18n.language}`}/>
              </Route>
            </Switch>
            <Footer openCookiesPopUp={() => setIsCookiesPopupOpen(true)} />
            <CookiesPopup open={isCookiesPopupOpen} close={() => setIsCookiesPopupOpen(false)} />
          </>
      }
    </>
  );
};

export default withRouter(App);
        
        
        
        
