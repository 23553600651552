import React from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import styles from './header.module.css';
import config from '../../config';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import optiusLogo from '../../assets/Optius Favicon.png';
import optiusBlueLogo from '../../assets/optius-logo-blue-text.png';
import optiusWhiteLogo from '../../assets/optius-logo-white-text.png';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageChange from '../language-change/language-change';

interface Props {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
}

const Header: React.FC<Props> = props => {
  const [isOnTop, setIsOnTop] = React.useState<boolean>(true);
  const location = useLocation();
  const appBarRef = React.createRef();

  React.useEffect(() => {
    if (appBarRef.current) {
      window.addEventListener('scroll', () => {
        setIsOnTop(window.scrollY < 10);
      });
    }
  }, [!appBarRef.current]);

  const isAndroid = () : boolean => {
    return navigator.userAgent.toLowerCase().indexOf('android') > -1;
  };

  const isIOS = () : boolean => {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  };

 

  const isTransparent = isOnTop && !location.pathname.includes('/terms-of-use') && !location.pathname.includes('/privacy-policy');
  
  const {t,i18n} = useTranslation();
  return (
    <AppBar
      elevation={0}
      ref={appBarRef}
      position="fixed"
      className={styles.header}
      color={isTransparent ? 'transparent' : 'inherit'}
      style={isTransparent ? { boxShadow: 'none' } : { backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}
    >
      <Toolbar className={styles.toolbar}>
        <Link to={`/${i18n.language}`}>
          <div className={styles.headerLogo}>
            <img className={styles.optiusLogo} src={optiusLogo} alt="Optius logo" />
            <img className={styles.optiusTextLogo} src={isTransparent ? optiusWhiteLogo : optiusBlueLogo} alt="Optius text logo" />
          </div>
        </Link>
        <div className={styles.rightSideMenu}>
          <div className={styles.buttonWrap}>
            <Button
              className={window.screen.width > 576 ? styles.button : isTransparent ? styles.phoneButton : styles.phoneScrollingButton}
              variant="contained"
              color="primary"
              onClick={() => {
                if (isAndroid()) {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Click',
                    label: 'Play Store',
                  });
                  ReactPixel.trackCustom('PlayStoreButtonClick');
                  window.location.href = config.androidAppUrl;
                } else if (isIOS()) {
                  ReactGA.event({
                    category: 'Button',
                    action: 'Click',
                    label: 'App Store',
                  });
                  ReactPixel.trackCustom('AppStoreButtonClick');
                  window.location.href = config.iosAppUrl;
                } else {
                  props.setIsMenuOpen(true);
                }
              }}
            >
              {t('Download')}
            </Button>
          </div>
          <div className={styles.iconsWrap}>
            <LanguageChange transparent={isTransparent}/>
            <IconButton className={window.screen.width > 576 ? isTransparent ? styles.menuButton : styles.menuButtonScrolling : isTransparent ? styles.menuPhoneButton : styles.menuPhoneScrollingButton} onClick={() => props.setIsMenuOpen(!props.isMenuOpen)}>
              {
                !props.isMenuOpen ?
                  <svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" width="41" height="23" viewBox="0 0 41 23" fill="none">
                    <path d="M38 3H3" stroke="white" strokeWidth="5" strokeLinecap="round"/>
                    <path d="M38 20.502H3" stroke="white" strokeWidth="5" strokeLinecap="round"/>
                  </svg>
                  :
                  <CloseIcon fontSize="inherit" />
              }
            </IconButton>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
