import React from 'react';
import styles from './news-articles.module.css';
import config from '../../config';
import techSavvyLogo from '../../assets/wy-tech-savvy-logo.png';
import politikenLogo from '../../assets/politiken-logo.png';
import fredericiaDagbladLogo from '../../assets/fredericia-dagblad-logo.png';
import financerLogo from '../../assets/financer-logo.png';
import lanioLogo from '../../assets/lanio-logo.png';
import fakdLogo from '../../assets/fakd-logo.png';

const NewsArticles: React.FC = () => {
  return (
    <section className={styles.section}>
      <div>
        <div onClick={() => window.open(config.frdbArticleUrl, '_blank')}>
          <img className={styles.fredericiaDagbladLogo} src={fredericiaDagbladLogo} alt="fredericiaDagbladLogo"></img>
        </div>
        <div onClick={() => window.open(config.techSavvyArticleUrl, '_blank')}>
          <img className={styles.techSavvyLogo} src={techSavvyLogo} alt="techSavvyLogo"></img>
        </div>
        <div onClick={() => window.open(config.politikenArticleUrl, '_blank')}>
          <img className={styles.politikenLogo} src={politikenLogo} alt="politikenLogo"></img>
        </div>
        <div onClick={() => window.open(config.lanioArticleUrl, '_blank')}>
          <img className={styles.lanioLogo} src={lanioLogo} alt="lanioLogo"></img>
        </div>
        <div onClick={() => window.open(config.financerArticleUrl, '_blank')}>
          <img className={styles.financerLogo} src={financerLogo} alt="financerLogo"></img>
        </div>
        <div onClick={() => window.open(config.fakdArticleUrl, '_blank')}>
          <img className={styles.fakdLogo} src={fakdLogo} alt="fakdLogo"></img>
        </div>
        
        <div onClick={() => window.open(config.frdbArticleUrl, '_blank')}>
          <img className={styles.fredericiaDagbladLogo} src={fredericiaDagbladLogo} alt="fredericiaDagbladLogo"></img>
        </div>
        <div onClick={() => window.open(config.techSavvyArticleUrl, '_blank')}>
          <img className={styles.techSavvyLogo} src={techSavvyLogo} alt="techSavvyLogo"></img>
        </div>
        <div onClick={() => window.open(config.politikenArticleUrl, '_blank')}>
          <img className={styles.politikenLogo} src={politikenLogo} alt="politikenLogo"></img>
        </div>
        <div onClick={() => window.open(config.lanioArticleUrl, '_blank')}>
          <img className={styles.lanioLogo} src={lanioLogo} alt="lanioLogo"></img>
        </div>
        <div onClick={() => window.open(config.financerArticleUrl, '_blank')}>
          <img className={styles.financerLogo} src={financerLogo} alt="financerLogo"></img>
        </div>
        <div onClick={() => window.open(config.fakdArticleUrl, '_blank')}>
          <img className={styles.fakdLogo} src={fakdLogo} alt="fakdLogo"></img>
        </div>
      </div>
    </section>
  );
};

export default NewsArticles;
