import React from 'react';
import styles from './description-container.module.css';
import { Trans, useTranslation } from 'react-i18next';

const DescriptionContainer: React.FC = () => {
  const { t } = useTranslation();
  return ( 
    <>
      <div className={styles.descriptionContainer}>
        <h1><Trans i18nKey="Assisting people with <br /> gaining insight into their consumption." /></h1>
        <p className={styles.description}>
          <Trans i18nKey="Today, Optius is the leading consumer app, assisting tens of thousands of users with free <br /> and automatic overview of their consumption. Whether it's personal finance or health, <br /> Optius inspires insightful choices." />
        </p>
      </div>

      <div className={styles.descriptionContainerPhone}>
        <h1><Trans i18nKey="Assisting people with <br /> gaining insight into their consumption." /></h1>
        <p className={styles.description}>
          {t('Today, Optius is the leading consumer app, assisting tens of thousands of users with free and automatic overview of their consumption. Whether it\'s personal finance or health, Optius inspires insightful choices.')}
        </p>
      </div>
    </>
  );
};

export default DescriptionContainer;
