import React from 'react';
import 'swiper/swiper-bundle.min.css';
import Card from '@material-ui/core/Card';
import styles from './carousel.module.css';
import { Trans, useTranslation } from 'react-i18next';
import StarRounded from '@material-ui/icons/StarRounded';
import StarHalfRounded from '@material-ui/icons/StarHalfRounded';
import AppleIcon from '@material-ui/icons/Apple';
import {ReactComponent as FacebookIcon} from '../../assets/facebook-icon.svg';
import {ReactComponent as AndroidIcon} from '../../assets/android.svg';
import {ReactComponent as QuotesIcon} from '../../assets/quote.svg';
import {ReactComponent as PaperIcon} from '../../assets/paper-icon.svg';
import InstagramIcon from '@material-ui/icons/Instagram';
import Masonry from 'react-masonry-css';

interface Message {
  title: string,
  message: string,
  author: string,
  platform: 'apple' | 'android' | 'facebook' | 'instagram' | 'special',
  rating: number
}


interface Icon {
  [id: string]: React.ReactNode
}

interface Props {
  forwardRef: React.RefObject<HTMLDivElement>;
  background: string;
}

const messages: Message[] = [
  {
    title: 'Overview of my spending',
    message: 'I use the Optius app to get an accurate overview of how much money I spend in grocery stores, but also on what exactly I\'m spending. It has really opened my eyes, for example, that I spend a lot of money on beverages. 🥤',
    author: 'Mayadrøm',
    platform: 'apple',
    rating: 5,
  },
  {
    title: 'Easy overview of your spending',
    message: 'Easy spending overview! Clearly the best app for budgeting when you want to track your finances and grocery spending. Highly recommended.',
    author: 'HLM_2023',
    platform: 'apple',
    rating: 5,
  },
  {
    title: 'Best budget app',
    message: 'Best budget app. I\'ve tried several budgeting apps, but this one surpasses them all. Even the paid apps don\'t come close to this one',
    author: 'Marie.dk',
    platform: 'apple',
    rating: 5,
  },
  {
    title: 'Really nice overview',
    message: 'Super nice app that is extremely easy to use and provides a really nice overview. Personal finance has just become 100 times easier 👌',
    author: 'Thea Horsted',
    platform: 'android',
    rating: 5,
  },
  {
    title: 'Control of your spending',
    message: 'A really good way to keep track of your grocery spending and receipts.',
    author: 'Carsten Wigant',
    platform: 'android',
    rating: 5,
  },
  {
    title: 'Very cool app!',
    message: 'Works great, awesome app. It provides an overview of spending and much more! Hence, 5 stars :)',
    author: 'Mejra Gobeljic',
    platform: 'android',
    rating: 5,
  }, 
  {
    title: 'Nice with recommendations',
    message: 'I find it to be a good and easy app, and it\'s nice to get advice.',
    author: 'Tina Holm',
    platform: 'android',
    rating: 5,
  },
  {
    title: 'Superb overview',
    message: 'Provides a superb overview of your spending',
    author: 'Line Riis Knudse',
    platform: 'android',
    rating: 5,
  },
  {
    title: 'Access to all receipts',
    message: 'With the Optius.app, you get access to all receipts purchased through Coop, Lidl Plus, etc., so you can see exactly what you spend your money on every month. It can help you save money or maybe set personal goals.',
    author: 'Frederikkeykkel',
    platform: 'instagram',
    rating: 5,
  },
  {
    title: 'Truely useful features',
    message: 'Really user-friendly design and truly useful features 🙏 I\'m especially fond of the link to the chemical magnifying glass 😍 As you can see on slide no. 2, it\'s an area I\'m quite attentive to, and it\'s nice to see that we have found some products that suit us 💪.',
    author: 'Budgetnoerden',
    platform: 'instagram',
    rating: 5,
  },
  {
    title: 'Helps save money',
    message: 'Looks really cool and a good initiative. A great way to get more overview and structure of your finances and to help you save up.',
    author: 'Daniel',
    platform: 'facebook',
    rating: 5,
  },
  {
    title: 'User-friendly app!',
    message: 'The Optius app is a user-friendly and easy-to-use app for the consumer. One of the features that has surprised us with the design is that we can go years back to see our consumption and thus track the household\'s consumption development.',
    author: 'Sabrina',
    platform: 'special',
    rating: 5,
  },
];

const breakpointColumnsObj = {
  default: 3,
  850: 1,
};


const icons: Icon = {
  'apple': <AppleIcon className={styles.appleIcon} />,
  'android': <AndroidIcon className={styles.androidIcon} />,
  'facebook': <FacebookIcon className={styles.facebookIcon} />,
  'instagram': <InstagramIcon className={styles.instagramIcon} />,
  'special': <PaperIcon className={styles.paperIcon} />,
};

const Carousel: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [numberOfMessages, setNumberOfMessages] = React.useState<number>(0);
  const [isSmallScreen, setIsSmallScreen] = React.useState<boolean>(window.innerWidth <= 850);

  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 850);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(()=>{
    setNumberOfMessages(isSmallScreen ? 4 : messages.length);
  },[isSmallScreen]);

  return (
    <section ref={props.forwardRef} className={styles.section} style={{backgroundImage: props.background}}>
      <div className={styles.header}>
        <h1>{t('What our +20,000 users say')}</h1>
        <p><Trans i18nKey="Download Optius today and start saving money"/></p>
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className={`${styles.myMasonryGrid} ${styles.messages}`}
        columnClassName={styles.myMasonryGridColumn}
      >
        {Array.from({ length: numberOfMessages}, (_, index) => index + 1).map( (_, index) => 
          <Card key={index} className={`${styles.card} masonry-item`}>
            <div className={styles.cardHeader}>
              <QuotesIcon className={styles.quotesIcon}/> 
              {Array.from({ length: messages[index].rating }, (_, index) => index + 1).map(i =>
                <StarRounded key={messages[index].author+i} className={styles.starIcon} />)}
              {messages[index].rating % 1 !== 0 ? <StarHalfRounded className={styles.starIcon} /> : ''}
            </div>
            <div className={styles.cardContent}>
              <h3>{t(messages[index].title)}</h3>
              <p>{t(messages[index].message)}</p>
            </div>
            <div className={styles.cardFooter}>
              <p>- {messages[index].author}</p>
              {icons[messages[index].platform]}
            </div>
          </Card>)}
        {numberOfMessages < messages.length ?
          <p className={styles.seeMore} onClick={() => setNumberOfMessages((prev: number) => (prev+4) > messages.length ? messages.length : prev+4)}>{t('See more reviews')}</p>
          :null
        }
      </Masonry>
      
    </section>
  );
};

export default Carousel;
