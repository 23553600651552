import React from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import styles from './drawer.module.css';
import config from '../../config';
import appStoreBadge from '../../assets/app-store-badge.png';
import appStoreBadgeEn from '../../assets/app-store-badge-en.svg';
import playStoreBadgeEn from '../../assets/play-store-badge-en.svg';
import playStoreBadge from '../../assets/play-store-badge.png';
import facebookLogo from '../../assets/facebook-logo.png';
import instagramLogo from '../../assets/instagram-logo.png';
import youtubeLogo from '../../assets/youtube-logo.png';
import { Link as MuiLink, List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Images from '../../models/images';

interface Props {
  onClose: () => void;
}

const badges : Images = {
  playStore: {
    da: playStoreBadge,
    no: playStoreBadge,
    en: playStoreBadgeEn,
  },
  appStore: {
    da: appStoreBadge,
    no: appStoreBadge,
    en: appStoreBadgeEn,
  },
};

const Drawer: React.FC<Props> = props => {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.drawerContainer}>
      <List className={styles.navigateOptions}>
        <Link to={`/${i18n.language}/about`} style={{textDecoration: 'none'}} onClick={()=>props.onClose()}><h3 className={styles.link}>{t('About Optius')}</h3></Link>
        <Link to={`/${i18n.language}/faq`} style={{textDecoration: 'none'}} onClick={()=>props.onClose()}><h3 className={styles.link}>{t('FAQ')}</h3></Link>
        <Link to={`/${i18n.language}/contact`} style={{textDecoration: 'none'}} onClick={()=>props.onClose()}><h3 className={styles.link}>{t('Contact')}</h3></Link>

      </List>

      <List className={styles.downloadAppOptions}>
        <a
          href={config.androidAppUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: 'Click',
              label: 'Play Store',
            });
            ReactPixel.trackCustom('PlayStoreButtonClick');
          }}
        >
          <img src={badges.playStore[i18n.language]} alt="Get in Play Store" />
        </a>
        <a
          href={config.iosAppUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event({
              category: 'Button',
              action: 'Click',
              label: 'App Store',
            });
            ReactPixel.trackCustom('AppStoreButtonClick');
          }}
        >
          <img src={badges.appStore[i18n.language]} alt="Get in App Store" />
        </a>
      </List>

      <List className={styles.socialMediaOptions}>
        <MuiLink href={config.facebookUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
          <img src={facebookLogo} alt="Facebook" />
        </MuiLink>
        <MuiLink href={config.instagramUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
          <img src={instagramLogo} alt="Instagram" />
        </MuiLink>
        <MuiLink href={config.youtubeUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
          <img src={youtubeLogo} alt="Youtube" />
        </MuiLink>
      </List>
    </div>
  );
};

export default Drawer;
