import React from 'react';
import styles from './rating-container.module.css';
import StarRounded from '@material-ui/icons/StarRounded';
import StarHalfRounded from '@material-ui/icons/StarHalfRounded';
import { useTranslation } from 'react-i18next';

interface Props {
  background: string;
  forwardRef: React.RefObject<HTMLDivElement>;
}

const RatingContainer: React.FC<Props> = props => {
  const { t } = useTranslation();
  return (
    <section ref={props.forwardRef} className={styles.section} style={{ background: props.background }}>
      <div className={styles.appleStore}>
        <h1>App Store 4,4 <span>{t('out of')} 5</span></h1>
        {Array.from({ length: 4 }, (_, index) => index + 1).map(i =>
          <StarRounded key={i} className={styles.starIcon} />)}
        <StarHalfRounded className={styles.starHalfIcon} />
            
      </div>
      <div className={styles.googlePlayStore}>
        <h1>Google Play 4,1 <span>{t('out of')} 5</span></h1>
        {Array.from({ length: 4 }, (_, index) => index + 1).map(i =>
          <StarRounded key={i} className={styles.starIcon} />)}
        <StarHalfRounded className={styles.starHalfIcon}/>
      </div>
    </section>
  );
};

export default RatingContainer;
