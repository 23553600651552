import React from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import IntroSection from '../../components/intro-section/intro-section';
import NewsArticles from '../../components/news-articles/news-articles';
import LogInSection from '../../components/log-in-section/log-in-section';
import SpendingSection from '../../components/spending-section/spending-section';
import StoresSection from '../../components/stores-section/stores-section';
import SupportContainer from '../../components/support-container/support-container';
import KemiluppenSection from '../../components/kemiluppen-section/kemiluppen-section';
import RecallsSection from '../../components/recalls-section/recalls-section';
import RatingContainer from '../../components/rating-container/rating-container';
import Carousel from '../../components/carousel/carousel';
import FeaturesContainer from '../../components/features-container/features-container';
import spendingBg from '../../assets/spending_bg.jpg';
import carouselBg from '../../assets/carousel_bg.png';


export let logInRef: React.RefObject<HTMLDivElement>;
export let spendingRef: React.RefObject<HTMLDivElement>;
export let storesRef: React.RefObject<HTMLDivElement>;
export let banksRef: React.RefObject<HTMLDivElement>;
export let supportRef: React.RefObject<HTMLDivElement>;
export let kemiluppenRef: React.RefObject<HTMLDivElement>;
export let recallsRef: React.RefObject<HTMLDivElement>;
export let carouselRef: React.RefObject<HTMLDivElement>;
export let featuresRef: React.RefObject<HTMLDivElement>;

const Home: React.FC = () => {
  logInRef = React.createRef<HTMLDivElement>();
  spendingRef = React.createRef<HTMLDivElement>();
  storesRef = React.createRef<HTMLDivElement>();
  banksRef = React.createRef<HTMLDivElement>();
  supportRef = React.createRef<HTMLDivElement>();
  kemiluppenRef = React.createRef<HTMLDivElement>();
  recallsRef = React.createRef<HTMLDivElement>();
  carouselRef = React.createRef<HTMLDivElement>();
  featuresRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: '/home'});
    ReactPixel.pageView();
    document.title = 'Optius - Automatisk overblik';
  }, []);

  
  return (
    <>
      <IntroSection />

      <NewsArticles />

      <LogInSection forwardRef={logInRef} background="#F6F6F6 0% 0% no-repeat padding-box" />

      <SpendingSection forwardRef={spendingRef}  background={`url(${spendingBg})`}/>   
      
      <RatingContainer forwardRef={supportRef} background="white" />

      <StoresSection forwardRef={storesRef} />
      
      <KemiluppenSection forwardRef={kemiluppenRef} />

      <SupportContainer forwardRef={supportRef} background="white" />

      <RecallsSection forwardRef={recallsRef}/>

      <FeaturesContainer forwardRef={featuresRef} background="white" />

      <Carousel forwardRef={carouselRef}  background={`url(${carouselBg})`}/>
    </>
  );
};

export default Home;
