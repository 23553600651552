import React from 'react';
import styles from './creators-section.module.css';
import benjamin from '../../assets/benjamin.jpg';
import sebastian from '../../assets/sebastian.jpg';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  forwardRef: React.RefObject<HTMLDivElement>;
  background: string;
}
const CreatorsSection: React.FC<Props> = props => {
  const { t } = useTranslation();
  return (
    <div ref={props.forwardRef} className={styles.section} style={{background: props.background}}>
      <h1>{t('Who are we?')}</h1>
      <div className={styles.images}>
        <div>
          <img src={benjamin} alt="Benjamin Heco" />
          <p>Benjamin Heco</p>
        </div>
        <div>
          <img src={sebastian} alt="Sebastian Lomborg Marshall" />
          <p>Sebastian Lomborg Marshall</p>
        </div>
      </div>
      <p className={styles.description}>
        <Trans i18nKey="Optius was founded by friends Benjamin Heco, Sebastian Lomborg Marshall, and Goran <br /> Lisak, with the ambition of providing people with a simple tool to take control of their <br /> finances, health, and climate." />
      </p>
      <p className={styles.phoneDescription}>
        {t('Optius was founded by friends Benjamin Heco, Sebastian Lomborg Marshall, and Goran Lisak, with the ambition of providing people with a simple tool to take control of their finances, health, and climate.')}
      </p>
    </div>
  );
};

export default CreatorsSection;
