import React from 'react';
import styles from './kemiluppen-section.module.css';
import tick from '../../assets/tick.png';
import { Trans, useTranslation } from 'react-i18next';
import Images from '../../models/images';
import kemiluppenScreenNo from '../../assets/kemiluppen-screen-no.png';
import kemiluppenScreenDa from '../../assets/kemiluppen-screen-da.png';
import kemiluppenScreenEn from '../../assets/kemiluppen-screen-en.png';


const images: Images = {
  desktop: {
    no: kemiluppenScreenNo,
    da: kemiluppenScreenDa,
    en: kemiluppenScreenEn,
  },
};

interface Props {
  forwardRef: React.RefObject<HTMLDivElement>;
}
const KemiluppenSection: React.FC<Props> = props => {
  const { i18n } = useTranslation();
  return (
    <>
      <div ref={props.forwardRef} className={styles.section}>
        <div className={styles.text}>
          <h1><Trans i18nKey="Would you like to avoid unwanted chemicals?"/></h1>
          <ul>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p><Trans i18nKey="Get a content overview of the purchased products <br /> containing unwanted chemicals"/></p>
            </li>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p><Trans i18nKey="Get direct notification when you buy products <br /> with unwanted chemicals" /></p>
            </li>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p><Trans i18nKey="Have a chemical-free everyday" /></p>
            </li>
          </ul>
        </div>
        <div className={styles.img}>
          <img src={images.desktop[i18n.language]} alt="Screen" />
        </div>
      </div>
      <div ref={props.forwardRef} className={styles.sectionPhone}>
        <div className={styles.text}>
          <h1><Trans i18nKey="Would you like to avoid unwanted chemicals?"/></h1>
          <div className={styles.img}>
            <img src={images.desktop[i18n.language]} alt="Phone screen" />
          </div>
          <ul>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p><Trans i18nKey="Get a content overview of the purchased <br /> products containing unwanted chemicals"/></p>
            </li>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p><Trans i18nKey="Get direct notification when you buy <br /> products with unwanted chemicals" /></p>
            </li>
            <li>
              <div><img className={styles.tick} src={tick} alt="Tick" /></div><p><Trans i18nKey="Have a chemical-free everyday" /></p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default KemiluppenSection;
