const config = {
  apiUrl: 'https://api.optius.app',
  iosAppUrl: 'https://apps.apple.com/us/app/optius/id1540805474',
  androidAppUrl: 'https://play.google.com/store/apps/details?id=app.optius',
  mapsUrl: 'https://goo.gl/maps/3L8xtSRZC7wRfugT9',
  facebookUrl: 'https://www.facebook.com/Optius-107345988284199',
  instagramUrl: 'https://www.instagram.com/optius.app',
  politikenArticleUrl: 'https://politiken.dk/forbrugogliv/art8518832/Ny-app-kan-hj%C3%A6lpe-dig-med-at-k%C3%B8be-sundere-varer',
  frdbArticleUrl: 'https://frdb.dk/artikel/to-m%C3%A6nd-en-ide-og-is-i-maven-benjamin-og-sebastian-satser-p%C3%A5-at-vinde-i-iv%C3%A6rks%C3%A6tter-lotteriet',
  youtubeUrl: 'https://www.youtube.com/@optius.app1',
  techSavvyArticleUrl: 'https://techsavvy.media/oekonomi-sundhed-og-klima-ugens-startup-guider-forbrugerne-til-det-rigtige-koeb-i-supermarkedet/',
  financerArticleUrl: 'https://financer.com/dk/blog/bedste-budget-apps/',
  lanioArticleUrl: 'https://www.xn--lnio-qoa.dk/blog/2016/08/anmeldelse-af-5-apps-som-kan-hjaelpe-med-at-holde-styr-paa-oekonomien',
  fakdArticleUrl: 'https://kost.dk/sites/default/files/2022-09/Anmeldelse%20af%20app%20og%20Mindeord.pdf?_gl=1*1ircgly*_up*MQ..*_ga*MTE2NDg4MDQzNS4xNjc2MDE0NDIy*_ga_F22HNVEHNG*MTY3NjAxNDQyMS4xLjEuMTY3NjAxNDUyNy4wLjAuMA',
};

export default config;