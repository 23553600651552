import React from 'react';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import styles from './contact.module.css';
import config from '../../config';
import { Link, List } from '@material-ui/core';
import IntroSection from '../../components/intro-contact-section/intro-section';
import facebookLogo from '../../assets/facebook-logo-whiteBg.png';
import instagramLogo from '../../assets/instagram-logo-whiteBg.png';
import youtubeLogo from '../../assets/youtube-logo-whiteBg.png';
import { Trans, useTranslation } from 'react-i18next';

const Contact: React.FC = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: '/contact'});
    ReactPixel.pageView();
    document.title = 'Optius - Kontakt';
  }, []);

  return (
    <>
      <IntroSection />

      <div className={styles.wrap}>
        <h1>{t('We are always ready to assist you')}</h1>
        <h3>{t('via email or on our social media.')}</h3>
        <div className={styles.content}>
          <div>
            <h4>
              {t('E-mail')} <br /> 
              <a href="mailto:kontakt@optius.dk">kontakt@optius.dk</a>
            </h4>
            <h4>
              {t('E-mail')} <a href="mailto:kontakt@optius.dk">kontakt@optius.dk</a>
            </h4>
            <List className={styles.socialMediaOptions}>
              <Link href={config.facebookUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
                <img src={facebookLogo} alt="Facebook" />
              </Link>
              <Link href={config.instagramUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
                <img src={instagramLogo} alt="Instagram" />
              </Link>
              <Link href={config.youtubeUrl} className={styles.socialMediaLink} target="_blank" rel="noopener noreferrer">
                <img src={youtubeLogo} alt="Youtube" />
              </Link>
            </List>
          </div>
          <div>
            <h4>{t('Opening Hours')}</h4>
            <div>
              <div>
                <p>{t('Monday - Thursday')}</p>
                <p>{t('Friday')}</p> 
                <p>{t('Saturday - Sunday')}</p>
              </div>
              <div>
                <p>08:00 - 20:00</p>
                <p>08:00 - 19:00</p> 
                <p>08:00 - 16:00</p>
              </div>
            </div>
            <p>
              <Trans i18nKey="Please note that emails are forwarded to the relevant support function and saved for documentation and security purposes."/>
            </p>
            <p>
              <Trans i18nKey="Please note that emails are forwarded to the relevant support function and saved for documentation and security purposes."/>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
