import React from 'react';
import styles from './spending-section.module.css';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  img: string;
  background: string;
  forwardRef: React.RefObject<HTMLDivElement>;
}

const SpendingSection: React.FC<Props> = props => {
  const { t } = useTranslation();
  return (
    <>
      <div ref={props.forwardRef} className={styles.section} style={{ background: props.background }}>
        <div className={styles.img}>
          <img src={props.img} alt="Screen" />
        </div>
        <div className={styles.text}>
          <p>
            <Trans i18nKey="Most people want to have an overview of their <br /> consumption, whether it's to save money <br /> or to achieve a healthier lifestyle."/>
          </p>
          <p>
            <Trans i18nKey="It's only a few who have time to immerse <br/> themselves and gain insight into their current consumption, to know what needs to be changed."/>
          </p>
          <p>
            <Trans i18nKey="<b>Real-world example:</b> <br /> During the development of Optius, one of our founders, Sebastian, became aware that he spent more than 900 DKK per year on plastic bags, which quickly resulted in him switching to reusable bags, as the money could be better spent elsewhere.">
              <b>Real-world example:</b> <br /> During the development of Optius, one of our founders, Sebastian, became aware that he spent more than 900 DKK per year on plastic bags, which quickly resulted in him switching to reusable bags, as the money could be better spent elsewhere.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="There can be a lot of money to save <br /> once you get an overview of your consumption, <br /> and Optius is therefore a help for you who <br /> wants to shop more consciously - to the benefit <br /> of your wallet. We believe that the more insight <br /> you have into your consumption, the easier it is to change it."/>
          </p>
        </div>
      </div>

      <div ref={props.forwardRef} className={styles.sectionPhone} style={{ background: props.background }}>
        <div className={styles.text}>
          <div className={styles.img}>
            <img src={props.img} alt="Phone screen" />
          </div>
          <p>
            {t('Most people want to have an overview of their consumption, whether it\'s to save money or to achieve a healthier lifestyle.')}
          </p>
          <p>
            {t('It\'s only a few who have time to immerse themselves and gain insight into their current consumption, to know what needs to be changed.')}
          </p>
          <p>
            <Trans i18nKey="<b>Real-world example:</b> <br /> During the development of Optius, one of our founders, Sebastian, became aware that he spent more than 900 DKK per year on plastic bags, which quickly resulted in him switching to reusable bags, as the money could be better spent elsewhere.">
              <b>Real-world example:</b> <br /> During the development of Optius, one of our founders, Sebastian, became aware that he spent more than 900 DKK per year on plastic bags, which quickly resulted in him switching to reusable bags, as the money could be better spent elsewhere.
            </Trans>
          </p>
          <p>
            {t('There can be a lot of money to save once you get an overview of your consumption, and Optius is therefore a help for you who wants to shop more consciously - to the benefit of your wallet. We believe that the more insight you have into your consumption, the easier it is to change it.')}
          </p>
        </div>
      </div>
    </>
  );
};

export default SpendingSection;
