import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  order: ['path', 'localStorage'],
  lookupLocalStorage: 'selectedLanguage',
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['da', 'en', 'no'],
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    transSupportBasicHtmlNodes: true,
    skipInterpolation: true,
    detection: options,
  });

export default i18n;