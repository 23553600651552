import React from 'react';
import styles from './intro-section.module.css';
import about from '../../assets/about.jpg';
import { Trans, useTranslation } from 'react-i18next';

const IntroSection: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.section} id="intro" style={{ backgroundImage: `url(${about})` }}>
      <div className={styles.introSection}>
        <h1>{t('About Optius')}</h1>
        <p className={styles.description}>
          <Trans i18nKey="Born at Middelfart Library, on the first floor, <br /> with a view over Lillebælt. Grew up with <br /> the aim of giving everyone more insight into their <br/> consumption."/>
        </p>
      </div>

      <div className={styles.introSectionPhone}>
        <div className={styles.textContainer}>
          <h1>{t('About Optius')}</h1>
          <p className={styles.description}>
            <Trans i18nKey="Born at Middelfart Library, on the first floor, <br /> with a view over Lillebælt. Grew up with <br /> the aim of giving everyone more insight into their <br/> consumption."/>
          </p>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
