import React from 'react';
import styles from './stores-section.module.css';
import { Trans, useTranslation } from 'react-i18next';
import Images from '../../models/images';
import storesPhoneDa from '../../assets/stores-screen-phone-da.png';
import storesPhoneNo from '../../assets/stores-screen-phone-no.png';
import storesDa from '../../assets/stores-screen-da.png';
import storesNo from '../../assets/stores-screen-no.png';

const images: Images = {
  desktop: {
    no: storesNo, 
    da: storesDa,
    en: storesDa,
  },
  phone: {
    no: storesPhoneNo,
    da: storesPhoneDa,
    en: storesPhoneDa,
  },
};
interface Props {
  forwardRef: React.RefObject<HTMLDivElement>;
}

const StoresSection: React.FC<Props> = props => {
  const { i18n } = useTranslation();
  return (
    <>
      <div ref={props.forwardRef} className={styles.section} style={{
        backgroundImage: `url(${images.desktop[i18n.language]})`}}>
        <div className={styles.text}>
          <h1><Trans i18nKey="Collect your receipts from more than 9,000 stores"/></h1>  
        </div>
      </div>

      <div ref={props.forwardRef} className={styles.sectionPhone} style={{
        backgroundImage: `url(${images.phone[i18n.language]})`}}>
        <div className={styles.text}>
          <h1><Trans i18nKey="Collect your receipts <br/> from more than 9,000 stores"/></h1>  
        </div>
      </div>
    </>
  );
};

export default StoresSection;
