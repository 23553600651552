import React from 'react';
import styles from './intro-section.module.css';
import faq from '../../assets/faq.jpg';
import { Trans, useTranslation } from 'react-i18next';

const IntroSection: React.FC = () => {
  const {t}= useTranslation();
  return (
    <section className={styles.section} id="intro" style={{ backgroundImage: `url(${faq})` }}>
      <div className={styles.introSection}>
        <h1>{t('FAQ')}</h1>
        <p className={styles.description}>
          <Trans i18nKey="Our FAQ contains the most frequently asked <br /> questions, so you can confidently get started <br /> using Optius." />
        </p>
      </div>
      
      <div className={styles.introSectionPhone}>
        <div className={styles.textContainer}>
          <h1>{t('FAQ')}</h1>
          <p className={styles.description}>
            <Trans i18nKey="Our FAQ contains the most frequently asked <br /> questions, so you can confidently get started <br /> using Optius." />
          </p>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
