import React from 'react';
import styles from './details-section.module.css';
import { Trans, useTranslation } from 'react-i18next';
import Images from '../../models/images';
import detailsDa from '../../assets/details-da.png';
import detailsNo from '../../assets/details-no.png';
import detailsEn from '../../assets/details-en.png';

const images: Images = {
  phone: {
    da: detailsDa,
    no: detailsNo,
    en: detailsEn,
  },
};

interface Props {
  background: string;
  forwardRef: React.RefObject<HTMLDivElement>;
}

const DetailsSection: React.FC<Props> = props => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div ref={props.forwardRef} className={styles.section} style={{ background: props.background }}>
        <div className={styles.text}>
          <h1>{t('You automatically get insight into:')}</h1>
          <ul>
            <li>
              <Trans i18nKey="Your digital receipts from more than 9,000 <br /> stores, so you can see what you've spent on everything from <br /> plastic bags and diapers to coffee and butter, etc." />
            </li>
            <li>
              <Trans i18nKey="If you buy personal care products with <br /> chemicals you'd rather avoid according to the Consumer Council Think" />
            </li>
            <li>
              <Trans i18nKey="If you have purchased goods that have been recalled by the Food Administration" />
            </li>
          </ul>
          <p>
            <Trans i18nKey="Feel free to follow along - there are continually new features coming, and we are constantly working to make Optius more relevant for you." />
          </p>
        </div>
        <div className={styles.img}>
          <img src={images.phone[i18n.language]} alt="Screen" />
        </div>
      </div>

      <div ref={props.forwardRef} className={styles.sectionPhone} style={{ background: props.background }}>
        <div className={styles.text}>
          <h1>{t('You automatically get insight into:')}</h1>
          <ul>
            <li>
              <Trans i18nKey="Your digital receipts from more than 9,000 stores, so you can see what you've spent on everything from plastic bags and diapers to coffee and butter, etc." />
            </li>
            <li>
              <Trans i18nKey="If you buy personal care products with chemicals you'd rather avoid according to the Consumer Council Think" />
            </li>
            <li>
              <Trans i18nKey="If you have purchased goods that have been recalled by <br/> the Food Administration" />
            </li>
          </ul>
          <p>
            <Trans i18nKey="Feel free to follow along - there are continually new features coming, and we are constantly working to make Optius more relevant for you." />
          </p>
        </div>
        <div className={styles.img}>
          <img src={images.phone[i18n.language]} alt="Phone screen" />
        </div>
      </div>
    </>
  );
};

export default DetailsSection;
