import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import './services/i18n';
import i18n from './services/i18n';

const theme = createTheme({
  palette: {
    primary: {
      main: '#093d7a',
    },
  },
});

const client = new ApolloClient({
  uri: 'https://cms.hecoetal.com/graphql',
  cache: new InMemoryCache(),
});



const getLanguageCode = async () => {

  const languageCodeRegex = /^(\/(da|en|no))(\/|$)/;
  const match = window.location.pathname.match(languageCodeRegex);
  const pathLanguage = !!i18n.language || !!match;

  const localStorageLanguage = localStorage.getItem('selectedLanguage');

  if (pathLanguage || localStorageLanguage) {
    //console.log('Language already set:', pathLanguage || localStorageLanguage);
    return;
  }

  try {
    const response = await fetch('https://ipapi.co/json/');
    const result = await response.json();
    const detectedLanguage = result.country === 'NO' ? 'no' : 'da';

    i18n.changeLanguage(detectedLanguage);
    localStorage.setItem('selectedLanguage', detectedLanguage);

    //console.log('Language set from API:', detectedLanguage);
  } catch (error) {
    console.error('Error fetching language from API:', error);
  }
};

getLanguageCode();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ScrollToTop />
          <Suspense fallback="loading...">
            <App/>
          </Suspense>
        </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
